export interface ErrorLike {
  message: string
}

export default function isErrorLike(value: unknown): value is ErrorLike {
  return (
    !!value &&
    typeof value === 'object' &&
    value !== null &&
    'message' in value &&
    typeof value.message === 'string'
  )
}
